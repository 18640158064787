import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";

import { Box, Typography, Tabs, Tab, Radio, RadioGroup, FormControlLabel } from "@mui/material";

import colors from "assets/theme/base/colors";
import AutoFixHighOutlinedIcon from "@mui/icons-material/AutoFixHighOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Grid from "@mui/material/Grid";
import { logAuthorizedWombatEvent } from "util/UTMFunctions";
import ShirtThumb from "assets/images/default-thumbnails/shirtthumb.png";
import ShedThumb from "assets/images/default-thumbnails/shedthumb.png";
import ChestThumb from "assets/images/default-thumbnails/chestthumb.png";
import TruckThumb from "assets/images/default-thumbnails/truckthumb.png";
import HammerThumb from "assets/images/default-thumbnails/hammerthumb.png";
import HelmetThumb from "assets/images/default-thumbnails/helmetthumb.png";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import Button from "@mui/material/Button";
import {
  convertBytesToMB,
  getFileType,
  generateUUID,
  getTimeDifferenceInSeconds,
} from "util/UtilFunctions";
import { useNavigate } from "react-router-dom";
import { useVisionUIController } from "context/index";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { HtmlTooltip } from "layouts/dashboards/default/components/CustomTooltip/index";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import CreditIcon from "assets/icons/crediticon.png";
import GradientIcon from "components/Custom/GradientIcon/index";
import Toggler from "../Toggler/index";
import InputSlider from "../InputSlider/index";
import { styled } from "@mui/material/styles";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import ReactCrop, { Crop } from "react-image-crop";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import AutoAwesomeMosaicOutlinedIcon from "@mui/icons-material/AutoAwesomeMosaicOutlined";
import "react-image-crop/dist/ReactCrop.css";
import GridViewIcon from "@mui/icons-material/GridView";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import CloseIcon from "@mui/icons-material/Close";
import Thumbnailer from "../../../../dashboards/default/components/Inspector/Thumbnailer/index";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import FormatPaintOutlinedIcon from "@mui/icons-material/FormatPaintOutlined";
import SliderNoInput from "../SliderNoInput/index";
import PanoramaPhotosphereIcon from "@mui/icons-material/PanoramaPhotosphere";
import { CompactPicker, SketchPicker } from "react-color";

const tabNames = {
  Generate: 0,
  Inpaint: 1,
  Mesh: 2,
  Jobs: 3,
  Assets: 4,
};

const ESTIMATED_INPAINT_TIME = 7; // 7 seconds

const RetextureForm2 = forwardRef((props, ref) => {
  const fileInput = React.useRef(null);
  const refImgFileInput = React.useRef(null);
  const [referenceImage, setReferenceImage] = useState(null);
  const [crop, setCrop] = useState({
    unit: "%", // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50,
    aspect: 1 / 1,
  });
  const cropRef = useRef(null);
  cropRef.current = crop;

  const imgRef = useRef(null);

  const navigate = useNavigate();

  const [controller, dispatch] = useVisionUIController();
  const { activeOrg, developerInfo } = controller;

  const [selectedMapResolution, setSelectedMapResolution] = useState("1K");
  const [inputConditioning, setInputConditioning] = useState("Image");

  const [assets, setAssets] = useState([]);
  const [assetPage, setAssetPage] = useState(1);

  const [tabValue, setTabValue] = React.useState(0);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [targetDate, setTargetDate] = useState(new Date());
  const [loadingJobStart, setLoadingJobStart] = useState(false);
  const [selectedRes, setSelectedRes] = useState("Depth");
  const [inputPointCloud, setInputPointCloud] = useState(null);

  useEffect(() => {
    if (activeOrg !== "") {
      // setAssetPage(1);
      fetchAssets();
    }
  }, [activeOrg]);

  const fetchAssets = async () => {
    const params = new URLSearchParams();
    params.set("page", assetPage);
    params.set("pageSize", 15);

    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/upload/3DmetadataForOrg`);
    url.search = params.toString();
    if (activeOrg !== "") {
      try {
        const response = await fetch(url, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "X-ORG-ID": activeOrg,
          },
        });

        const data = await response.json();
        console.log("RETEXTURE ASSETS data", data);

        const assetArray = [];
        const allowedFileTypes = ["glb", "gltf", "fbx", "obj"];
        for (let i = 0; i < data.fileNames.length; i++) {
          if (allowedFileTypes.includes(getFileType(data.fileNames[i]))) {
            assetArray.push({
              name: data.fileNames[i],
              thumbnail: data.thumbnailPaths[i],
              asset_id: data.model_ids[i],
              file_path: data.filePaths[i],
            });
          }
        }
        setAssets(assetArray);
      } catch (error) {
        console.error("RETEXTURE ASSETS error", error);
      }
    }
  };

  useEffect(() => {
    console.log("assetPage updated", assetPage);
    if (assetPage > 0) {
      fetchAssets();
    } else {
      setAssetPage(1);
    }
  }, [assetPage]);

  const handleAssetPageChange = (newPage) => {
    setAssetPage(newPage);
  };

  const handleInputConditioningChange = (event, newConditioning) => {
    console.log("prompting_handleInputConditioningChange", newConditioning);
    if (newConditioning !== null) setInputConditioning(newConditioning);
  };

  const applyFieldsForJob = (job) => {
    console.log("pulling applyFieldsForJob", job);
    const prompt = document.getElementById("prompt");
    const negativePrompt = document.getElementById("negative-prompt");
    const seed = document.getElementById("seed");
    // const preserveuvs = document.getElementById("preserveuvs");
    // const materialmaps = document.getElementById("materialmaps");

    prompt.value = job.textPrompt;
    negativePrompt.value = job.jobParams.negative_prompt;
    seed.value = job.jobParams.seed;
    preserveuvs.checked = job.jobParams.preserve_uvs;
    // materialmaps.checked = job.jobParams.material_maps;

    setSelectedMapResolution(
      job.jobParams.texture_resolution === 1024
        ? "1K"
        : job.jobParams.texture_resolution === 2048
        ? "2K"
        : "4K"
    );
  };

  useImperativeHandle(ref, () => ({
    applyFieldsForJob: applyFieldsForJob,
  }));

  const handleChange = (event) => {
    props.handleSelectedMesh(-1);
    const fileUploaded = event.target.files[0];

    if (convertBytesToMB(fileUploaded.size) > 1000) {
      props.startSnackbar({
        message: "File size too large. Please upload a file less than 1GB",
        type: "error",
      });
      logAuthorizedWombatEvent("generatePageUploadError", {
        message: "File size too large. Please upload a file less than 1GB",
      });
      return;
    }
    logAuthorizedWombatEvent("fileUploadedToGeneratePage", {
      name: fileUploaded.name,
      type: getFileType(fileUploaded.name),
    });
    props.handleUploadedFile(fileUploaded);
    // reset event target value so that the same file can be uploaded again
    event.target.value = null;
  };

  const handleResChange = (event, newRes) => {
    console.log("prompting_handleResChange", newRes);
    if (newRes !== null) setSelectedRes(newRes);
  };

  const handleRefImgChange = (event) => {
    const fileUploaded = event.target.files[0];

    if (!fileUploaded) return;

    if (convertBytesToMB(fileUploaded.size) > 1000) {
      props.startSnackbar({
        message: "File size too large. Please upload an image or point cloud smaller than 1GB",
        type: "error",
      });
      logAuthorizedWombatEvent("tiledFormUploadError", {
        message: "File size too large. Please upload an image or point cloud smaller than 1GB",
      });
      return;
    }
    logAuthorizedWombatEvent("patternImageUploadedToTiledForm", {
      name: fileUploaded.name,
      type: getFileType(fileUploaded.name),
    });
    // props.handleUploadedFile(fileUploaded);
    // if the file type is .ply then set the inputPointCloud state variable
    if (getFileType(fileUploaded.name) === "ply") {
      if (tabValue === tabNames.Mesh) {
        setReferenceImage(fileUploaded);
		setInputConditioning("Point Cloud");
      } else {
        props.startSnackbar({
          message: "Please select the Mesh tab to upload a point cloud.",
          type: "error",
        });
      }
    } else {
      setReferenceImage(fileUploaded);
	  setInputConditioning("Image");
      // get image dimensions and use that to set a square crop using % units
      const image = new Image();
      image.src = URL.createObjectURL(fileUploaded);
      image.onload = () => {
        const imageWidth = image.width;
        const imageHeight = image.height;

        // Reduce the size by 10% to add a 5% padding on each side
        const size = Math.min(imageWidth, imageHeight) * 0.9;
        const x = (imageWidth - size) / 2;
        const y = (imageHeight - size) / 2;

        // Convert these to percentages
        setCrop({
          unit: "%",
          x: (x / imageWidth) * 100,
          y: (y / imageHeight) * 100,
          width: (size / imageWidth) * 100,
          height: (size / imageHeight) * 100,
        });
      };
    }
    event.target.value = null;
  };

  const onCropComplete = async (file) => {
    console.log("onCropComplete", file, crop);

    const createImage = (url) =>
      new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener("load", () => resolve(image));
        image.addEventListener("error", (error) => reject(error));
        image.src = url;
      });

    if (file && crop.width && crop.height) {
      const objectUrl = URL.createObjectURL(file);
      try {
        const image = await createImage(objectUrl);
        const croppedBlob = await getCroppedImg(image, cropRef.current, "newFile.jpeg");
        console.log("onCropComplete croppedBlob", croppedBlob);
        return croppedBlob;
      } catch (e) {
        console.error("Failed to create image", e);
      } finally {
        URL.revokeObjectURL(objectUrl);
      }
    }
  };

  const getCroppedImg = async (image, crop, fileName) => {
    const canvas = document.createElement("canvas");

    // These scale factors should remain the same for both 'px' and '%'
    const scaleX = image.naturalWidth / imgRef.current.width;
    const scaleY = image.naturalHeight / imgRef.current.height;

    let cropX = crop.x;
    let cropY = crop.y;
    let cropWidth = crop.width;
    let cropHeight = crop.height;

    // Convert to pixels if the unit is in percentages
    if (crop.unit === "%") {
      cropX = (crop.x / 100) * imgRef.current.width;
      cropY = (crop.y / 100) * imgRef.current.height;
      cropWidth = (crop.width / 100) * imgRef.current.width;
      cropHeight = (crop.height / 100) * imgRef.current.height;
    }

    // Scale to natural dimensions
    cropX *= scaleX;
    cropY *= scaleY;
    cropWidth *= scaleX;
    cropHeight *= scaleY;

    // Set canvas dimensions to natural dimensions
    canvas.width = cropWidth;
    canvas.height = cropHeight;

    const ctx = canvas.getContext("2d");

    ctx.drawImage(image, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error("Canvas is empty"));
            return;
          }
          blob.name = fileName;
          resolve(blob);
        },
        "image/jpeg",
        1
      );
    });
  };

  const handleFileButtonClick = () => {
    fileInput.current.click();
  };

  const handleInpaintSubmit = async (e) => {
    logAuthorizedWombatEvent("inpaintAISubmitButtonClicked", {});

    console.log("form submitted", e);
    const prompt = e.target.elements.prompt.value;
    if (prompt === "") {
      logAuthorizedWombatEvent("inpaintAISubmitError", {
        error: "Please enter a prompt.",
      });
      props.startSnackbar({
        message: "Please enter a prompt.",
        type: "error",
      });
      return;
    }

    const negative_prompt = e.target.elements.negativePrompt.value;
    const seed = e.target.elements.seed.value;

    const job_params = {
      negative_prompt: negative_prompt,
      seed: seed,
    };

    if (job_params.seed === "") {
      job_params.seed = Math.floor(Math.random() * 4294967296) + "";
    } else if (parseInt(job_params.seed) < 1 || parseInt(job_params.seed) > 4294967296) {
      job_params.seed = Math.floor(Math.random() * 4294967296) + "";
    }

    // job_params.controlnet_conditioning = e.target.elements.cn_depth.value;
    // job_params.controlnet_conditioning_normal = e.target.elements.cn_normal.value;
    job_params.guidance_scale = e.target.elements.guidance_scale.value;
    job_params.texture_resolution = 1024;
    if (process.env.NODE_ENV !== "production") {
      // job_params.owner = e.target.elements.owner.value;
      job_params.owner = "rohan";
    }

    console.log("job_params", job_params);
    setLoadingJobStart(true);
    const preFlightTarget = new Date();
    setCurrentDate(new Date());
    preFlightTarget.setSeconds(preFlightTarget.getSeconds() + ESTIMATED_INPAINT_TIME);
    setTargetDate(preFlightTarget);
    const jobId = await props.handleSubmitInpaintJob({ prompt: prompt, job_params: job_params });
    // const jobId = await props.handleSubmitJob({ prompt: prompt, job_params: job_params }, true);

    // if jobId is a string then do setJobId
    if (typeof jobId === "string") {
      props.startSnackbar({
        message: "Edit Job started successfully.",
        type: "success",
      });
      pollRecursively(jobId, prompt);
    } else {
      setLoadingJobStart(false);
      e.target.elements.prompt.value = prompt;
    }
  };

  const handleMeshSubmit = async (e) => {
    logAuthorizedWombatEvent("meshAISubmitButtonClicked", {});

    console.log("form submitted", e);
    const job_params = {};
    setLoadingJobStart(true);
    const preFlightTarget = new Date();
    setCurrentDate(new Date());
    preFlightTarget.setSeconds(preFlightTarget.getSeconds() + ESTIMATED_INPAINT_TIME);
    setTargetDate(preFlightTarget);

    const elevated_angle = e.target.elements.elevated_angle.checked;

    job_params.elevated_angle = elevated_angle;

    let imageUUID = "";
    if (inputConditioning !== "Image") {
      job_params.input_conditioning = "point_cloud";
      imageUUID = generateUUID();
      // get blob from ply file in referenceImage state variable
      const plyFileBlob = new Blob([referenceImage], { type: "text/plain" });
      const plyFile = new File([plyFileBlob], "pointcloud.ply");
      await props.uploadImage(plyFile, imageUUID);
      job_params.reference_image_id = imageUUID;
    } else if (referenceImage) {
      imageUUID = generateUUID();
      const croppedImageBlob = await onCropComplete(referenceImage);
      // convert blob to File using referenceImage name

      console.log("croppedImageBlob", croppedImageBlob);

      if (!croppedImageBlob) {
        logAuthorizedWombatEvent("generateAISubmitError", {
          error: "Input image is required.",
        });
        props.startSnackbar({
          message: "Input image is required.",
          type: "error",
        });
        return;
      }

      const croppedImageFile = new File([croppedImageBlob], referenceImage.name);
      // use a tag to download croppedImageBlob
      const cropURL = URL.createObjectURL(croppedImageBlob);

      await props.uploadImage(croppedImageFile, imageUUID);
      job_params.reference_image_id = imageUUID;
    }
    console.log("after image uploaded");
    const jobId = await props.handleMeshSubmit({ prompt: prompt, job_params: job_params });
    // const jobId = await props.handleSubmitJob({ prompt: prompt, job_params: job_params }, true);

    // if jobId is a string then do setJobId
    if (typeof jobId === "string") {
      props.startSnackbar({
        message: "Mesh Job started successfully.",
        type: "success",
      });
      pollMeshRecursively(jobId, prompt);
    } else {
      setLoadingJobStart(false);
      e.target.elements.prompt.value = prompt;
    }
  };

  const pollMeshRecursively = async (jobId, prompt) => {
    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/ai/retextureStatus`);
    const params = new URLSearchParams();
    params.set("jobId", jobId);
    url.search = params.toString();
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": activeOrg,
      },
    });
    const jsonData = await response.json();
    console.log("retextureStatus response", jsonData);
    if (
      jsonData.job_status === "completed" ||
      jsonData.job_status === "failed" ||
      jsonData.job_status === "cancelled"
    ) {
      // get the texture map from the job
      setLoadingJobStart(false);

      if (jsonData.job_status === "completed") {
        props.startSnackbar({
          message: "Mesh Job completed successfully.",
          type: "success",
        });
        props.handleUploadedFile(jsonData.output_s3_path);
        const job = {
          job_id: jobId,
          job_name: "mesh",
          resVersionId: jsonData.res_version_id,
          res_asset_path: jsonData.output_s3_path,
          job_params: {},
          text_prompt: "Image Input",
          version_id: "",
        };
        props.addCompletedJob(job);
      } else {
        props.startSnackbar({
          message: "Mesh Job failed. Please try again.",
          type: "error",
        });
        // job: {job_id, job_name, version_id, job_params, text_prompt}
      }
    } else {
      setTimeout(() => {
        pollMeshRecursively(jobId, prompt);
      }, 1000);
    }
  };

  const pollRecursively = async (jobId, prompt) => {
    const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/ai/retextureStatus`);
    const params = new URLSearchParams();
    params.set("jobId", jobId);
    url.search = params.toString();
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-ORG-ID": activeOrg,
      },
    });
    const jsonData = await response.json();
    console.log("retextureStatus response", jsonData);
    if (
      jsonData.job_status === "completed" ||
      jsonData.job_status === "failed" ||
      jsonData.job_status === "cancelled"
    ) {
      // get the texture map from the job
      const textureUrl = jsonData.texture_map_path;
      setLoadingJobStart(false);

      if (jsonData.job_status === "completed") {
        props.startSnackbar({
          message: "Edit Job completed successfully.",
          type: "success",
        });
        props.handleNewInpaintTexture(textureUrl);
        props.handleOldTextureMap(jsonData.texture_map_path, true);
        const job = {
          job_id: jobId,
          job_name: "3dinpaint",
          resVersionId: jsonData.res_version_id,
          res_asset_path: jsonData.output_s3_path,
          job_params: {},
          text_prompt: prompt,
          version_id: "",
        };
        props.addCompletedJob(job);
      } else {
        props.startSnackbar({
          message: "Job failed. Please try again.",
          type: "error",
        });
        // job: {job_id, job_name, version_id, job_params, text_prompt}
      }
    } else {
      setTimeout(() => {
        pollRecursively(jobId, prompt);
      }, 1000);
    }
  };

  const eraseMask = () => {
    props.handleNewInpaintTexture(props.oldTextureMap);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (props.runningJobs.length > 0 && props.subscription.name !== "polyhive_plus_plan") {
    //   // pop a modal, then return
    //   props.handleJobStartModalOpen();
    //   logAuthorizedWombatEvent("freeUserAttemptedToQueueMultipleJobs", {});

    //   return;
    // }

    const submitbutton = e.target.elements.submitbutton;
    // disable submit button for 2 seconds, then enable
    submitbutton.disabled = true;
    console.log("submit button disabled");
    setTimeout(() => {
      submitbutton.disabled = false;
      console.log("submit button enabled");
    }, 2000);

    console.log("submitbutton", submitbutton);

    if (tabValue === 1) {
      console.log("INPAINTINGFORM SUBMIT");
      handleInpaintSubmit(e);
      return;
    } else if (tabValue === 2) {
      console.log("INPAINTINGFORM SUBMIT");
      handleMeshSubmit(e);
      return;
    }

    logAuthorizedWombatEvent("generateAISubmitButtonClicked", {});

    console.log("form submitted", e);
    const prompt = e.target.elements.prompt.value;
    if (prompt === "") {
      logAuthorizedWombatEvent("generateAISubmitError", {
        error: "Please enter a prompt.",
      });
      props.startSnackbar({
        message: "Please enter a prompt.",
        type: "error",
      });
      return;
    }

    console.log("TABVALUE: ", tabValue);

    const negative_prompt = e.target.elements.negativePrompt.value;
    const seed = e.target.elements.seed.value;
    const preserve_uvs = e.target.elements.preserveuvs.checked;

    // const material_maps = e.target.elements.materialmaps.checked;
    // const zy_symmetry = e.target.elements.bilateralsymmetry.checked;
    const job_params = {
      negative_prompt: negative_prompt,
      seed: seed,
      preserve_uvs: preserve_uvs,
      material_maps: false,
      // zy_symmetry: zy_symmetry,
    };

    if (process.env.NODE_ENV !== "production") {
      // job_params.owner = e.target.elements.owner.value;
      job_params.owner = "rohan";
    }
    if (job_params.seed === "") {
      job_params.seed = Math.floor(Math.random() * 100000) + "";
    } else if (parseInt(job_params.seed) < 1 || parseInt(job_params.seed) > 100000) {
      job_params.seed = Math.floor(Math.random() * 100000) + "";
    }

    job_params.guidance_scale = 7.5;
    job_params.region_threshold = 0.2;
    job_params.use_existing_texture = false;
    job_params.strict_projection_masking = true;
    job_params.sd_output_resolution = props.selectedRes === "Standard" ? 512 : 768;
    job_params.control_conditioning_type = selectedRes;
    // SDXL_MIGRATE
    // job_params.sd_output_resolution = 1024;

    job_params.special_model = props.selectedRes;
    job_params.texture_resolution =
      selectedMapResolution === "1K" ? 1024 : selectedMapResolution === "2K" ? 2048 : 4096;

    // if (referenceImage) job_params.reference_weight = e.target.elements.reference_weight.value * 3;

    // Form validation
    //if (!/^[a-zA-Z0-9, ]+$/.test(prompt)) {
    if (prompt.includes("{") || prompt.includes("}")) {
      logAuthorizedWombatEvent("generateAISubmitError", {
        error: "Invalid prompt. Curly braces are not allowed.",
      });
      props.startSnackbar({
        message: "Invalid prompt. Curly braces are not allowed.",
        type: "error",
      });
      return;
    }

    if (negative_prompt.includes("{") || negative_prompt.includes("}")) {
      logAuthorizedWombatEvent("generateAISubmitError", {
        error: "Invalid negative prompt. Curly braces are not allowed.",
      });
      props.startSnackbar({
        message: "Invalid negative prompt. Curly braces are not allowed.",
        type: "error",
      });
      return;
    }
    console.log("job_params", job_params);

    // let imageUUID = "";
    // if (referenceImage) {
    // 	imageUUID = generateUUID();
    // 	const croppedImageBlob = await onCropComplete(referenceImage);
    // 	// convert blob to File using referenceImage name

    // 	console.log("croppedImageBlob", croppedImageBlob);

    // 	if (!croppedImageBlob) {
    // 		logAuthorizedWombatEvent("generateAISubmitError", {
    // 			error: "Images must be cropped to a square.",
    // 		});
    // 		props.startSnackbar({
    // 			message: "Images must be cropped to a square.",
    // 			type: "error",
    // 		});
    // 		return;
    // 	}

    // 	const croppedImageFile = new File([croppedImageBlob], referenceImage.name);
    // 	// use a tag to download croppedImageBlob
    // 	const cropURL = URL.createObjectURL(croppedImageBlob);

    // 	await props.uploadImage(croppedImageFile, imageUUID);
    // 	job_params.reference_s3_path = cropURL;
    // }
    // console.log("after image uploaded");
    // job_params.reference_image_id = imageUUID;
    props.handleSubmitJob({
      prompt: prompt,
      job_params: job_params,
    });
  };

  const handleSymmetry = (e) => {
    const symettry = e.target.checked;
    console.log("symettry", symettry);
    props.handleSymmetry(symettry);
  };

  const handleMapResolutionChange = (event, newRes) => {
    console.log("prompting_handleMapResolutionChange", newRes);
    if (newRes !== null) setSelectedMapResolution(newRes);
  };

  const handleRefImgFileButtonClick = () => {
    refImgFileInput.current.click();
  };

  const setModel = (obj) => {
    props.setModel(obj);
    props.handleSelectedMesh(-2);
  };

  const undoInpaint = () => {
    console.log("undoInpaint");
    props.undoInpaint();
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="file"
        ref={fileInput}
        style={{ display: "none" }}
        multiple={true}
        onChange={handleChange}
        accept=".glb, .gltf, .fbx, .obj"
      />
      <input
        type="file"
        ref={refImgFileInput}
        style={{ display: "none" }}
        multiple={true}
        onChange={handleRefImgChange}
        accept=".png, .jpg, .jpeg, .ply"
      />
      <Box
        className="retexture_form"
        sx={{
          display: props.open ? "flex" : "none",
        }}
      >
        <BasicTabs
          DockElement={props.DockElement}
          assets={assets}
          assetPage={assetPage}
          handleAssetPageChange={handleAssetPageChange}
          setModel={setModel}
          value={tabValue}
          setTabValue={setTabValue}
          runningJobs={props.runningJobs}
          handleFileButtonClick={handleFileButtonClick}
          referenceImage={referenceImage}
          setReferenceImage={setReferenceImage}
          handleRefImgFileButtonClick={handleRefImgFileButtonClick}
          crop={crop}
          setCrop={setCrop}
          props={props}
          imgRef={imgRef}
          handleInpaintingForm={props.handleInpaintingForm}
          eraseMask={eraseMask}
          undoInpaint={undoInpaint}
          selectedRes={selectedRes}
          handleResChange={handleResChange}
          inputConditioning={inputConditioning}
          handleInputConditioningChange={handleInputConditioningChange}
        />
      </Box>
    </form>
  );
});

const GenerateForm = ({
  formValues,
  setFormValues,
  setTabValue,
  handleFileButtonClick,
  referenceImage,
  setReferenceImage,
  handleRefImgFileButtonClick,
  crop,
  setCrop,
  props,
  imgRef,
  selectedRes,
  handleResChange,
}) => {
  const handleChange = (event) => {
    console.log("generateForm_handleChange", event.target);
    const { name, value, type, checked } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  return (
    <Box className="retexture_container">
      <Box className="retexture_form_body" mt={1}>
        <Typography className="retexture_form_subheader">
          Select a mesh to retexture or upload a custom mesh
        </Typography>
        <hr className="rt_form_hr mb_2" />
        <Box className="select_container mauto">
          <Button
            className="py-5 f_small c_white bg_darkest fw_500 default_btn mr_1 br_1px br_icondisabled"
            startIcon={
              <GradientIcon>
                <GridViewIcon className="default_btn_icon" />
              </GradientIcon>
            }
            onClick={() => {
              setTabValue(4);
            }}
          >
            Select Mesh
          </Button>

          <Button
            className="py-5 f_small c_white bg_darkest fw_500 default_btn mr_1 br_1px br_icondisabled"
            startIcon={
              <GradientIcon>
                <FileUploadOutlinedIcon className="default_btn_icon" />
              </GradientIcon>
            }
            onClick={() => {
              handleFileButtonClick();
            }}
          >
            Upload
          </Button>
        </Box>
        {/* Text Prompt Area */}
        <Box className="retexture_form_ta" mb={1} mt={1}>
          <Box className="title_tooltip_container">
            <Typography className="f_small c_white fw_500">Prompt</Typography>
            <HtmlTooltip
              title={
                <Box width={200}>
                  <Typography className="f_small c_white fw_500">Prompt</Typography>
                  <Typography className="f_small c_iconenabled fw_400">
                    Describe your texture using text. It is recommended to be as descriptive as
                    possible.
                  </Typography>
                </Box>
              }
              placement="top"
            >
              <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
            </HtmlTooltip>
          </Box>
          <Box className="retexture_form_textarea_container">
            <textarea
              className="retexture_form_textarea"
              placeholder="Describe your texture in a few words..."
              id="prompt"
              name="prompt"
              value={formValues.prompt || ""} // Use formValues for value
              onChange={handleChange} // Update formValues on change
            />
          </Box>
        </Box>
        {/* <hr className="rt_form_hr" /> */}
        <Box className="retexture_form_ta" mb={1} mt={1}>
          <Box className="title_tooltip_container">
            <Typography className="f_small c_white fw_500">Negative Prompt</Typography>
            <HtmlTooltip
              title={
                <Box width={200}>
                  <Typography className="f_small c_white fw_500">Negative Prompt</Typography>
                  <Typography className="f_small c_iconenabled fw_400">
                    Describe what you don't want your texture to be. It is recommended to be as
                    descriptive as possible.
                  </Typography>
                </Box>
              }
              placement="top"
            >
              <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
            </HtmlTooltip>
          </Box>
          <Box className="retexture_form_textarea_container">
            <textarea
              className="retexture_form_textarea"
              placeholder="Describe what you don't want your texture to be..."
              id="negative-prompt"
              name="negativePrompt"
              value={formValues.negativePrompt || ""} // Use formValues for value
              onChange={handleChange} // Update formValues on change
            />
          </Box>
        </Box>
        {/* <hr className="rt_form_hr" /> */}
        <Box mb={2} className="retexture_form_ta" mt={1}>
          <Box className="title_tooltip_container">
            <Typography className="f_small c_white fw_500">Seed</Typography>
            <HtmlTooltip
              title={
                <Box width={200}>
                  <Typography className="f_small c_white fw_500">Seed</Typography>
                  <Typography className="f_small c_iconenabled fw_400">
                    A seed is a number used to introduce randomness into the AI generation. This is
                    useful for generating different variations with the same prompt.
                  </Typography>
                </Box>
              }
              placement="top"
            >
              <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
            </HtmlTooltip>
          </Box>
          <Box className="retexture_form_input_container">
            <input
              type="number"
              className="retexture_form_input"
              placeholder="A number between 1 and 100000"
              id="seed"
              name="seed"
              value={formValues.seed || ""} // Use formValues for value
              onChange={handleChange} // Update formValues on change
              autoComplete="off"
            />
          </Box>
        </Box>
        {/* <Box className="align_center_row" mb={2} mt={1}>
					<Box className="title_tooltip_container">
						<Typography className="f_small c_white fw_500">Reference Image</Typography>
						<HtmlTooltip
							title={
								<Box width={200}>
									<Typography className="f_small c_white fw_500">Reference Image</Typography>
									<Typography className="f_small c_iconenabled fw_400">
										Upload an image that will guide the style of the generated texture.
									</Typography>
								</Box>
							}
							placement="top"
						>
							<InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
						</HtmlTooltip>
					</Box>
					{referenceImage === null && (
						<Button
							className="default_btn bg_hivegradient align_center_row justify_center c_white f_small fw_500 ml_1"
							onClick={handleRefImgFileButtonClick}
						>
							Upload
							<FileUploadRoundedIcon className="icon_medium c_white" />
						</Button>
					)}

					{referenceImage && (
						<Box className="align_center_row">
							<Typography className="f_small c_white fw_500 ml_1 mw_200px wrap_ellipses">
								{referenceImage.name}
							</Typography>
							<CloseRoundedIcon
								className="icon_small c_white ml_1 br_100 bg_lightblack icon_medium"
								onClick={() => {
									setReferenceImage(null);
								}}
							/>
						</Box>
					)}
				</Box>{" "}
				{referenceImage && (
					<>
						<Box className="align_center_row" mb={1} mt={0}>
							<ReactCrop aspect={1 / 1} crop={crop} onChange={(newCrop) => setCrop(newCrop)}>
								<img ref={imgRef} src={URL.createObjectURL(referenceImage)} />
							</ReactCrop>
						</Box>
						<Box mb={1}>
							<InputSlider
								title="Reference Weight"
								name="reference_weight"
								tooltipString="The reference weight controls how much the reference image will influence the generated texture. A higher reference weight will result in a texture that more closely resembles the reference image."
								min={0}
								max={1}
								step={0.1}
								defaultValue={0.3}
							/>
						</Box>
					</>
				)} */}
        <Box className="retexture_form_header mt_2 mb_2">
          <Box className="header_flex">
            <Box className="title_tooltip_container">
              <Typography className="f_small c_white fw_500">Guidance Type</Typography>
              <HtmlTooltip
                title={
                  <Box width={300}>
                    <Typography className="f_small c_white fw_500">Guidance Type</Typography>
                    <Typography className="f_small c_iconenabled fw_400">
                      {/* copy for L/R symmetry */}
                      Select the map type that you would like to use to guide the texture
                      generation.
                    </Typography>
                  </Box>
                }
                placement="top"
              >
                <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
              </HtmlTooltip>
            </Box>
          </Box>
          <Box className="header_flex">
            <Toggler
              options={["Depth", "Normal"]}
              select={selectedRes}
              handleChange={handleResChange}
            />
          </Box>
        </Box>
        <Box className="retexture_form_header">
          <Box className="header_flex">
            <Box className="title_tooltip_container">
              <Typography className="f_small c_white fw_500">Auto UV Unwrap</Typography>
              <HtmlTooltip
                title={
                  <Box width={300}>
                    <Typography className="f_small c_white fw_500">Auto UV Unwrap</Typography>
                    <Typography className="f_small c_iconenabled fw_400">
                      When this option is enabled, a new UV map will be created. If this is
                      disabled, the UV's that are included with the mesh will be preserved when
                      generating the new texture.
                    </Typography>
                  </Box>
                }
                placement="top"
              >
                <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
              </HtmlTooltip>
            </Box>
          </Box>
          <Box className="header_flex">
            <label class="checkbox">
              <input
                type="checkbox"
                name="preserveuvs"
                checked={formValues.preserveuvs || false} // Use formValues for checked state
                onChange={handleChange}
              />
              <svg viewBox="0 0 21 18">
                <symbol id="tick-path2" viewBox="0 0 21 18" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.22003 7.26C5.72003 7.76 7.57 9.7 8.67 11.45C12.2 6.05 15.65 3.5 19.19 1.69"
                    fill="none"
                    stroke-width="2.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </symbol>
                <defs>
                  <mask id="tick">
                    <use class="tick mask" href="#tick-path2" />
                  </mask>
                </defs>
                <use class="tick" href="#tick-path2" stroke="currentColor" />
                <path
                  fill="white"
                  mask="url(#tick)"
                  d="M18 9C18 10.4464 17.9036 11.8929 17.7589 13.1464C17.5179 15.6054 15.6054 17.5179 13.1625 17.7589C11.8929 17.9036 10.4464 18 9 18C7.55357 18 6.10714 17.9036 4.85357 17.7589C2.39464 17.5179 0.498214 15.6054 0.241071 13.1464C0.0964286 11.8929 0 10.4464 0 9C0 7.55357 0.0964286 6.10714 0.241071 4.8375C0.498214 2.39464 2.39464 0.482143 4.85357 0.241071C6.10714 0.0964286 7.55357 0 9 0C10.4464 0 11.8929 0.0964286 13.1625 0.241071C15.6054 0.482143 17.5179 2.39464 17.7589 4.8375C17.9036 6.10714 18 7.55357 18 9Z"
                />
              </svg>
              <svg class="lines" viewBox="0 0 11 11">
                <path d="M5.88086 5.89441L9.53504 4.26746" />
                <path d="M5.5274 8.78838L9.45391 9.55161" />
                <path d="M3.49371 4.22065L5.55387 0.79198" />
              </svg>
            </label>
          </Box>
        </Box>
        {/* Generate Material Maps */}
        {/* <Box className="retexture_form_header">
					<Box className="header_flex">
						<Box className="title_tooltip_container">
							<Typography className="f_small c_white fw_500">Generate Material Maps</Typography>
							<HtmlTooltip
								title={
									<Box width={300}>
										<Typography className="f_small c_white fw_500">
											Generate Material Maps
										</Typography>
										<Typography className="f_small c_iconenabled fw_400">
											When this flag is enabled Polyhive will generate a set of material textures
											(normal, smoothness, and metallic maps) for your asset. These textures allow
											your asset to react more realistically to light (ex. causing shiny parts to
											produce specular highlights).
										</Typography>
									</Box>
								}
								placement="top"
							>
								<InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
							</HtmlTooltip>
						</Box>
					</Box>
					<Box className="header_flex">
						<label class="checkbox">
							<input
								type="checkbox"
								id="materialmaps"
								name="materialmaps"
								checked={formValues.materialmaps || false} // Use formValues for checked state
								onChange={handleChange}
							/>
							<svg viewBox="0 0 21 18">
								<symbol id="tick-path1" viewBox="0 0 21 18" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M5.22003 7.26C5.72003 7.76 7.57 9.7 8.67 11.45C12.2 6.05 15.65 3.5 19.19 1.69"
										fill="none"
										stroke-width="2.25"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</symbol>
								<defs>
									<mask id="tick1">
										<use class="tick mask" href="#tick-path1" />
									</mask>
								</defs>
								<use class="tick" href="#tick-path1" stroke="currentColor" />
								<path
									fill="white"
									mask="url(#tick1)"
									d="M18 9C18 10.4464 17.9036 11.8929 17.7589 13.1464C17.5179 15.6054 15.6054 17.5179 13.1625 17.7589C11.8929 17.9036 10.4464 18 9 18C7.55357 18 6.10714 17.9036 4.85357 17.7589C2.39464 17.5179 0.498214 15.6054 0.241071 13.1464C0.0964286 11.8929 0 10.4464 0 9C0 7.55357 0.0964286 6.10714 0.241071 4.8375C0.498214 2.39464 2.39464 0.482143 4.85357 0.241071C6.10714 0.0964286 7.55357 0 9 0C10.4464 0 11.8929 0.0964286 13.1625 0.241071C15.6054 0.482143 17.5179 2.39464 17.7589 4.8375C17.9036 6.10714 18 7.55357 18 9Z"
								/>
							</svg>
							<svg class="lines" viewBox="0 0 11 11">
								<path d="M5.88086 5.89441L9.53504 4.26746" />
								<path d="M5.5274 8.78838L9.45391 9.55161" />
								<path d="M3.49371 4.22065L5.55387 0.79198" />
							</svg>
						</label>
					</Box>
				</Box> */}
        {/* DEV PARAMS */}
        {process.env.NODE_ENV === "production" ? null : (
          <>
            <hr className="rt_form_hr mt_2" />

            <Box mb={2} className="retexture_form_ta">
              <Typography className="f_small c_white fw_500" mt={1}>
                DEV ONLY: Owner
              </Typography>
              <Box className="retexture_form_input_container">
                <input
                  className="retexture_form_input"
                  placeholder="Owner"
                  id="owner"
                  name="owner"
                  checked={formValues.owner || ""} // Use formValues for checked state
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Box>
            </Box>
            {/* Dry Run */}
          </>
        )}
      </Box>
      <Box className="retexture_form_footer">
        <hr className="rt_form_hr" />
        <Button
          className={
            props.runningJobs.length === 0
              ? "rt_form_btn"
              : props.subscription && props.subscription.name === "polyhive_plus_plan"
              ? "rt_form_btn"
              : "rt_form_btn"
          }
          type="submit"
          size="large"
          name="submitbutton"
        >
          Generate
        </Button>
      </Box>
    </Box>
  );
};

const MeshForm = ({
  referenceImage,
  setReferenceImage,
  handleRefImgFileButtonClick,
  inputConditioning,
  handleInputConditioningChange,
  crop,
  setCrop,
  props,
  imgRef,
}) => {
  return (
    <Box className="retexture_container">
      <Box className="retexture_form_body" mt={1}>
        <Box className="align_center_row" mb={2} mt={1}>
          <Box className="title_tooltip_container">
            <Typography className="f_small c_white fw_500">Input Conditioning Type</Typography>
            <HtmlTooltip
              title={
                <Box width={200}>
                  <Typography className="f_small c_white fw_500">Input Conditioning</Typography>
                  <Typography className="f_small c_iconenabled fw_400">
                    Upload an image or point cloud that will guide the generation.
                  </Typography>
                </Box>
              }
              placement="top"
            >
              <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
            </HtmlTooltip>
          </Box>
          <Toggler
            options={["Image", "Point Cloud"]}
            select={inputConditioning}
            handleChange={handleInputConditioningChange}
          />
        </Box>
        <Box className="align_center_row" mb={2} mt={1}>
          <Box className="title_tooltip_container">
            <Typography className="f_small c_white fw_500">Upload Input</Typography>
            <HtmlTooltip
              title={
                <Box width={200}>
                  <Typography className="f_small c_white fw_500">Input Conditioning</Typography>
                  <Typography className="f_small c_iconenabled fw_400">
                    Upload an image or point cloud that will guide the generation.
                    <b>Accepted formats:</b> .png, .jpg, .jpeg, .ply
                  </Typography>
                </Box>
              }
              placement="top"
            >
              <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
            </HtmlTooltip>
          </Box>

          {referenceImage === null && (
            <Button
              className="default_btn bg_hivegradient align_center_row justify_center c_white f_small fw_500 ml_1"
              onClick={handleRefImgFileButtonClick}
            >
              Upload
              <FileUploadRoundedIcon className="icon_medium c_white" />
            </Button>
          )}

          {referenceImage && (
            <Box className="align_center_row">
              <Typography className="f_small c_white fw_500 ml_1 mw_200px wrap_ellipses">
                {referenceImage.name}
              </Typography>
              <CloseRoundedIcon
                className="icon_small c_white ml_1 br_100 bg_lightblack icon_medium"
                onClick={() => {
                  setReferenceImage(null);
                }}
              />
            </Box>
          )}
        </Box>{" "}
        {inputConditioning === "Image" && referenceImage && (
          <>
            <Box className="align_center_row" mb={1} mt={0}>
              <ReactCrop crop={crop} onChange={(newCrop) => setCrop(newCrop)}>
                <img ref={imgRef} src={URL.createObjectURL(referenceImage)} />
              </ReactCrop>
            </Box>
          </>
        )}
        <Box className="retexture_form_header">
          <Box className="header_flex">
            <Box className="title_tooltip_container">
              <Typography className="f_small c_white fw_500">Elevated Angle</Typography>
              <HtmlTooltip
                title={
                  <Box width={300}>
                    <Typography className="f_small c_white fw_500">
                      Elevated Input Image Angle
                    </Typography>
                    <Typography className="f_small c_iconenabled fw_400">
                      Check this box if the input image is taken from an elevated angle, looking
                      down on the object.
                    </Typography>
                  </Box>
                }
                placement="top"
              >
                <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
              </HtmlTooltip>
            </Box>
          </Box>
          <Box className="header_flex">
            <label class="checkbox">
              <input type="checkbox" name="elevated_angle" />
              <svg viewBox="0 0 21 18">
                <symbol id="tick-path2" viewBox="0 0 21 18" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.22003 7.26C5.72003 7.76 7.57 9.7 8.67 11.45C12.2 6.05 15.65 3.5 19.19 1.69"
                    fill="none"
                    stroke-width="2.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </symbol>
                <defs>
                  <mask id="tick">
                    <use class="tick mask" href="#tick-path2" />
                  </mask>
                </defs>
                <use class="tick" href="#tick-path2" stroke="currentColor" />
                <path
                  fill="white"
                  mask="url(#tick)"
                  d="M18 9C18 10.4464 17.9036 11.8929 17.7589 13.1464C17.5179 15.6054 15.6054 17.5179 13.1625 17.7589C11.8929 17.9036 10.4464 18 9 18C7.55357 18 6.10714 17.9036 4.85357 17.7589C2.39464 17.5179 0.498214 15.6054 0.241071 13.1464C0.0964286 11.8929 0 10.4464 0 9C0 7.55357 0.0964286 6.10714 0.241071 4.8375C0.498214 2.39464 2.39464 0.482143 4.85357 0.241071C6.10714 0.0964286 7.55357 0 9 0C10.4464 0 11.8929 0.0964286 13.1625 0.241071C15.6054 0.482143 17.5179 2.39464 17.7589 4.8375C17.9036 6.10714 18 7.55357 18 9Z"
                />
              </svg>
              <svg class="lines" viewBox="0 0 11 11">
                <path d="M5.88086 5.89441L9.53504 4.26746" />
                <path d="M5.5274 8.78838L9.45391 9.55161" />
                <path d="M3.49371 4.22065L5.55387 0.79198" />
              </svg>
            </label>
          </Box>
        </Box>
        {/* {process.env.NODE_ENV === "production" ? null : (
					<>
						<hr className="rt_form_hr mt_2" />

						<Box mb={2} className="retexture_form_ta">
							<Typography className="f_small c_white fw_500" mt={1}>
								DEV ONLY: Owner
							</Typography>
							<Box className="retexture_form_input_container">
								<input
									className="retexture_form_input"
									placeholder="Owner"
									id="owner"
									name="owner"
									autoComplete="off"
								/>
							</Box>
						</Box>
					</>
				)} */}
      </Box>
      <Box className="retexture_form_footer">
        <hr className="rt_form_hr" />
        <Button
          className={
            props.runningJobs.length === 0
              ? "rt_form_btn"
              : props.subscription && props.subscription.name === "polyhive_plus_plan"
              ? "rt_form_btn"
              : "rt_form_btn"
          }
          type="submit"
          size="large"
          name="submitbutton"
        >
          Generate
        </Button>
      </Box>
    </Box>
  );
};

const InpaintingForm = ({
  formValues,
  setFormValues,
  props,
  imgRef,
  currentDate,
  targetDate,
  loadingJobStart,
  eraseMask,
  undoInpaint,
}) => {
  const handleChange = (event) => {
    console.log("generateForm_handleChange", event.target);
    const { name, value, type, checked } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const renderLoading = () => {
    return (
      <>
        <Box className="max_container">
          <Box
            className="small_progress mb_1"
            sx={{
              width: `${
                100 -
                  (getTimeDifferenceInSeconds(currentDate, targetDate) / ESTIMATED_INPAINT_TIME) *
                    100 >
                100
                  ? 100
                  : 100 -
                    (getTimeDifferenceInSeconds(currentDate, targetDate) / ESTIMATED_INPAINT_TIME) *
                      100
              }%`,
            }}
          ></Box>
          <Typography className="f_medium c_iconenabled fw_400">
            Painting masked region...
          </Typography>
        </Box>
      </>
    );
  };

  const renderForm = () => {
    return (
      <Box className="retexture_container">
        <Box className="retexture_form_body" mt={1}>
          <Box className="retexture_form_ta" mb={1}>
            <Typography className="f_small c_white fw_500" mt={1} mb={1}>
              Brush Size
            </Typography>
            <Box className="align_center_row">
              <Box
                className="br_8 d_flex d_align_center d_justify_center mr_1"
                width={50}
                height={50}
              >
                <Box
                  className="bg_white br_100"
                  sx={{
                    width: `${props.paintbrushSize}px`,
                    height: `${props.paintbrushSize}px`,
                  }}
                ></Box>
              </Box>
              <SliderNoInput
                name="cn_depth"
                min={5}
                max={50}
                step={5}
                defaultValue={10}
                value={props.paintbrushSize}
                onChange={props.handlePaintbrushSize}
              />
            </Box>
          </Box>
          <Box className="retexture_form_ta" mb={1}>
            <Box className="align_center_row">
              <Button
                className="default_btn bg_icondisabled align_center_row justify_center c_white f_small fw_500 ml_1"
                onClick={eraseMask}
              >
                Clear Mask
              </Button>
              <Button
                className="default_btn bg_icondisabled align_center_row justify_center c_white f_small fw_500 ml_1"
                onClick={undoInpaint}
              >
                Undo Inpaint
              </Button>
            </Box>
          </Box>
          {/* <Box className="retexture_form_ta" mb={1}>
						<Typography className="f_small c_white fw_500" mt={1} mb={1}>
							Brush Color
						</Typography>
						<Box className="align_center_row">
							<CompactPicker
								disableAlpha={true}
								color={props.paintbrushColor}
								onChangeComplete={(color) => {
									props.handlePaintbrushColor(color.hex);
								}}
								width="50%"
							/>
						</Box>
					</Box> */}
          {/* Text Prompt Area */}
          <Box className="retexture_form_ta" mb={1} mt={1}>
            <Box className="title_tooltip_container">
              <Typography className="f_small c_white fw_500">Prompt</Typography>
              <HtmlTooltip
                title={
                  <Box width={200}>
                    <Typography className="f_small c_white fw_500">Prompt</Typography>
                    <Typography className="f_small c_iconenabled fw_400">
                      Describe your texture using text. It is recommended to be as descriptive as
                      possible.
                    </Typography>
                  </Box>
                }
                placement="top"
              >
                <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
              </HtmlTooltip>
            </Box>
            <Box className="retexture_form_textarea_container">
              <textarea
                className="retexture_form_textarea"
                placeholder="Describe your texture in a few words..."
                id="prompt"
                name="prompt"
              />
            </Box>
          </Box>
          {/* <hr className="rt_form_hr" /> */}
          <Box className="retexture_form_ta" mb={1} mt={1}>
            <Box className="title_tooltip_container">
              <Typography className="f_small c_white fw_500">Negative Prompt</Typography>
              <HtmlTooltip
                title={
                  <Box width={200}>
                    <Typography className="f_small c_white fw_500">Negative Prompt</Typography>
                    <Typography className="f_small c_iconenabled fw_400">
                      Describe what you don't want your texture to be. It is recommended to be as
                      descriptive as possible.
                    </Typography>
                  </Box>
                }
                placement="top"
              >
                <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
              </HtmlTooltip>
            </Box>
            <Box className="retexture_form_textarea_container">
              <textarea
                className="retexture_form_textarea"
                placeholder="Describe what you don't want your texture to be..."
                id="negative-prompt"
                name="negativePrompt"
              />
            </Box>
          </Box>
          {/* <hr className="rt_form_hr" /> */}

          <Box mb={2} className="retexture_form_ta" mt={1}>
            <Box className="title_tooltip_container">
              <Typography className="f_small c_white fw_500">Seed</Typography>
              <HtmlTooltip
                title={
                  <Box width={200}>
                    <Typography className="f_small c_white fw_500">Seed</Typography>
                    <Typography className="f_small c_iconenabled fw_400">
                      A seed is a number used to introduce randomness into the AI generation. This
                      is useful for generating different variations with the same prompt.
                    </Typography>
                  </Box>
                }
                placement="top"
              >
                <InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
              </HtmlTooltip>
            </Box>
            <Box className="retexture_form_input_container">
              <input
                type="number"
                className="retexture_form_input"
                placeholder="A number between 1 and 10000000"
                id="seed"
                name="seed"
                autoComplete="off"
              />
            </Box>
          </Box>

          {/* <Box className="retexture_form_header mt_2 mb_0">
						<Box className="header_flex">
							<Box className="title_tooltip_container">
								<Typography className="f_small c_white fw_500">Model</Typography>
								<HtmlTooltip
									title={
										<Box width={300}>
											<Typography className="f_small c_white fw_500">Texture Quality</Typography>
											<Typography className="f_small c_iconenabled fw_400">
												Select the AI model you would like to use to generate your texture. Polyhive
												HD will result in a higher quality texture but will take longer to generate.
											</Typography>
										</Box>
									}
									placement="top"
								>
									<InfoOutlinedIcon className="title_tooltip_icon c_icondisabled" />
								</HtmlTooltip>
							</Box>
						</Box>
					 <Box className="header_flex">
							<Toggler
								options={["Standard", "Polyhive HD"]}
								select={props.selectedRes}
								handleChange={props.handleResChange}
							/>
						</Box>
								
					</Box> 
					<InputSlider
						title="Control Net Depth"
						name="cn_depth"
						tooltipString="Metalness is the measure of how metallic a surface is. 
						A value of 0.0 means the surface is completely dielectric (non-metallic), 
						and a value of 1.0 means the surface is a perfect metal. Values in between are for blending between the two. 
						A value of 0.5 means the surface is half metal and half dielectric."
						min={0}
						max={1}
						step={0.01}
						defaultValue={0.3}
					/>
					<InputSlider
						title="Control Net Normal"
						name="cn_normal"
						tooltipString="Metalness is the measure of how metallic a surface is. 
						A value of 0.0 means the surface is completely dielectric (non-metallic), 
						and a value of 1.0 means the surface is a perfect metal. Values in between are for blending between the two. 
						A value of 0.5 means the surface is half metal and half dielectric."
						min={0}
						max={1}
						step={0.01}
						defaultValue={0.05}
					/> */}
          <InputSlider
            title="Guidance Scale"
            name="guidance_scale"
            tooltipString="Metalness is the measure of how metallic a surface is. 
						A value of 0.0 means the surface is completely dielectric (non-metallic), 
						and a value of 1.0 means the surface is a perfect metal. Values in between are for blending between the two. 
						A value of 0.5 means the surface is half metal and half dielectric."
            min={7.5}
            max={30}
            step={0.5}
            defaultValue={17}
          />
        </Box>

        <Box className="retexture_form_footer">
          <hr className="rt_form_hr" />
          <Button
            className={
              props.runningJobs.length === 0
                ? "rt_form_btn"
                : props.subscription && props.subscription.name === "polyhive_plus_plan"
                ? "rt_form_btn"
                : "rt_form_btn"
            }
            type="submit"
            size="large"
            name="submitbutton"
          >
            Generate
          </Button>
        </Box>
      </Box>
    );
  };
  return loadingJobStart ? renderLoading() : renderForm();
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BasicTabs = ({
  DockElement,
  assets,
  assetPage,
  handleAssetPageChange,
  setModel,
  value,
  setTabValue,
  runningJobs,
  handleFileButtonClick,
  referenceImage,
  setReferenceImage,
  handleRefImgFileButtonClick,
  inputConditioning,
  handleInputConditioningChange,
  crop,
  setCrop,
  props,
  imgRef,
  handleInpaintingForm,
  eraseMask,
  undoInpaint,
  selectedRes,
  handleResChange,
}) => {
  const [formValues, setFormValues] = useState({}); // Form values managed here
  const [inpaintFormValues, setInpaintFormValues] = useState({}); // Form values managed here
  const MemoizedGenerateForm = React.memo(GenerateForm);

  const handleChange = (event, newValue) => {
    console.log("TABS: handleChange", newValue);
    setTabValue(newValue);
  };

  useEffect(() => {
    console.log("TABS:value updated", value);
    if (value === 1) {
      handleInpaintingForm(true);
    } else {
      handleInpaintingForm(false);
    }

    // if tab is not on mesh and reference image is a ply, set reference image to null
    if (
      value !== 2 &&
      referenceImage &&
      referenceImage.name &&
      referenceImage.name.includes(".ply")
    ) {
      setReferenceImage(null);
    }
  }, [value]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <StyledTabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
          <StyledTab
            label="Texture"
            icon={
              <GradientIcon>
                <ViewInArOutlinedIcon className="sidenav_icon mr_0" />
              </GradientIcon>
            }
            {...a11yProps(0)}
          />
          <StyledTab
            label="AI Editing"
            icon={
              <GradientIcon>
                <FormatPaintOutlinedIcon className="sidenav_icon mr_0" />
              </GradientIcon>
            }
            {...a11yProps(1)}
          />
          <StyledTab
            label="Mesh"
            icon={
              <>
                <GradientIcon>
                  <PanoramaPhotosphereIcon className="sidenav_icon mr_0" />
                </GradientIcon>
                {/* <Box className="tab_badge bg_focus">Beta</Box> */}
              </>
            }
            {...a11yProps(2)}
          />
          <StyledTab
            label="Jobs"
            icon={
              <>
                <GradientIcon>
                  <FormatListBulletedRoundedIcon className="sidenav_icon mr_0" />
                </GradientIcon>
                {runningJobs.length > 0 && (
                  <Box className="running_jobs_count">{runningJobs.length}</Box>
                )}
              </>
            }
            {...a11yProps(3)}
          />
          <StyledTab
            label="Assets"
            icon={
              <GradientIcon>
                <GridViewIcon className="sidenav_icon mr_0" />
              </GradientIcon>
            }
            {...a11yProps(4)}
          />
        </StyledTabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <GenerateForm
          formValues={formValues}
          setFormValues={setFormValues}
          setTabValue={setTabValue}
          handleFileButtonClick={handleFileButtonClick}
          referenceImage={referenceImage}
          setReferenceImage={setReferenceImage}
          handleRefImgFileButtonClick={handleRefImgFileButtonClick}
          crop={crop}
          setCrop={setCrop}
          props={props}
          imgRef={imgRef}
          selectedRes={selectedRes}
          handleResChange={handleResChange}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <InpaintingForm
          formValues={formValues}
          setFormValues={setFormValues}
          props={props}
          imgRef={imgRef}
          eraseMask={eraseMask}
          undoInpaint={undoInpaint}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <MeshForm
          referenceImage={referenceImage}
          setReferenceImage={setReferenceImage}
          handleRefImgFileButtonClick={handleRefImgFileButtonClick}
          inputConditioning={inputConditioning}
          handleInputConditioningChange={handleInputConditioningChange}
          crop={crop}
          setCrop={setCrop}
          props={props}
          imgRef={imgRef}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        {DockElement}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <AssetsTab
          assets={assets}
          assetPage={assetPage}
          handleAssetPageChange={handleAssetPageChange}
          setModel={setModel}
        />
      </CustomTabPanel>
    </Box>
  );
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const StyledTabs = styled((props) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: "4px",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "80%",
    backgroundColor: "#635ee7",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  flexDirection: "column",
  fontWeight: 500,
  fontSize: theme.typography.pxToRem(14),
  marginRight: theme.spacing(1),
  color: "rgba(255, 255, 255, 0.7)",
  width: "50px !important",
  minWidth: "85px !important",
  "&.Mui-selected": {
    color: "#fff",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

const AssetsTab = ({ assets, assetPage, handleAssetPageChange, setModel }) => {
  const handlePrev = () => {
    handleAssetPageChange(assetPage - 1);
  };

  const handleNext = () => {
    handleAssetPageChange(assetPage + 1);
  };

  return (
    <Box className="retexture_container">
      <Box className="retexture_form_body">
        {assets.length === 0 ? (
          <Box className="no_assets_container">
            <ReportProblemRoundedIcon className="no_assets_icon" />
            <Typography className="f_small c_iconenabled fw_500">No assets found</Typography>
          </Box>
        ) : (
          <Grid container spacing={2} className="mt_1">
            {assets.map((asset, index) => {
              return (
                <Grid item xs={4} key={index} className="center_column">
                  <Box
                    className="asset_card center_column"
                    onClick={() => {
                      setModel({ id: asset.asset_id, path: asset.file_path });
                    }}
                  >
                    <img src={asset.thumbnail} alt={asset.name} className="asset_thumbnail" />
                    <Typography className="f_small c_iconenabled fw_500 asset_title wrap_ellipses">
                      {asset.name}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
      <Box className="retexture_form_footer">
        <hr className="rt_form_hr" />
        <Box className="align_center_row w100 d_justify_center">
          <Box className="dock_flex" onClick={handlePrev}>
            <ArrowBackRoundedIcon
              className={
                assetPage > 1 ? "dock_arrow c_white bg_light" : "dock_arrow c_icondisabled bg_dark"
              }
            />
          </Box>
          <Box className="dock_flex" onClick={handleNext}>
            <ArrowForwardRoundedIcon className={"dock_arrow c_white bg_light"} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RetextureForm2;
