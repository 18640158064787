import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard PRO React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Authentication layout components
// import footer from home components
import Footer from "../../layout/footer/Footer.jsx";

// Vision UI Dashboard PRO React page layout routes

import React, { useState } from "react";

import pageRoutes from "page.routes";

// Vision UI Dashboard PRO React theme functions
import colors from "assets/theme/base/colors";

// Vision UI Dashboard PRO React theme functions
import tripleLinearGradient from "assets/theme/functions/tripleLinearGradient";
import HomeImage from "assets/images/homegraphic-hexagon.png";
import Button from "@mui/material/Button";

import { Suspense, lazy, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";

import Wall from "assets/models/Wall.glb";
import Typing from "react-typing-animation";
import { Box, Typography, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import HexagonContainer from "../../components/HexagonContainer/index";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
// import { useForm, ValidationError } from "@formspree/react";

const Contact = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const gridItemStyle = {
    position: "relative",
    margin: "auto",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    // convert formData to json

    const formDataObj = Object.fromEntries(formData.entries());
    console.log("formdata", formDataObj);

    try {
      const resp = await fetch("https://formspree.io/f/meqdnlbp", {
        method: "POST",
        body: formData,
        mode: "no-cors",
      });

      console.log("resp", resp);
    } catch (err) {
      console.log(err);
    }

    setFormSubmitted(true);
  };

  const formContent = (
    <>
      <VuiTypography className="c_textblack f_raleway f_xlarge fw_600">
        Tell us about yourself and we'll get back to you shortly.
      </VuiTypography>
      <br />
      <form onSubmit={handleSubmit}>
        <VuiTypography className="c_icondisabled fw_500 f_mid">Full Name</VuiTypography>
        <input className="retexture_form_input_light bg_brightwhite" name="name" />
        <VuiTypography className="c_icondisabled fw_500 f_mid mt_2">Company Email</VuiTypography>
        <input className="retexture_form_input_light bg_brightwhite" name="email" />
        <VuiTypography className="c_icondisabled fw_500 f_mid mt_2">Company</VuiTypography>
        <input className="retexture_form_input_light bg_brightwhite" name="company" />
        <VuiTypography className="c_icondisabled fw_500 f_mid mt_2">Message</VuiTypography>
        <Box className="retexture_form_textarea_container">
          <textarea className="retexture_form_textarea_light bg_brightwhite" name="message" />
        </Box>
        <Button type="submit" fullWidth className="bg_black c_white rt_form_btn  mt_2">
          Submit
        </Button>
      </form>
    </>
  );

  const formSubmittedContent = (
    <>
      <VuiTypography className="c_textblack f_xlarge fw_600">
        Thank you for your interest in Polyhive! We will get back to you shortly.
      </VuiTypography>
      <br />
      <VuiTypography className="c_textblack f_large fw_400">
        In the meantime, feel free to check out our Dashboard with a free trial! Please note that
        the trial version offers a preview of our platform's capabilities, but the highest quality
        texture generations and advanced features are exclusively available in our Studio version.
      </VuiTypography>
      <Button
        className="bg_quaternaryfocusdark c_white hero_btn f_mid fw_500 mt_2"
        endIcon={<ArrowForwardRoundedIcon />}
      >
        Start Free Trial
      </Button>
    </>
  );

  return (
    <PageLayout background={colors.white.bright}>
      <DefaultNavbar
        routes={pageRoutes}
        action={{
          type: "external",
          route: "/",
        }}
        transparent
      />
      <VuiBox width="100%" background="#fff">
        <VuiBox
          alignItems="center"
          justifyContent="center"
          mt={15}
          px={"48px"}
          sx={{ margin: "100px auto 0px", width: { sm: "100%", md: "1000px", lg: "1296px" } }}
        >
          <Grid container spacing={2} sx={{ margin: "0 60" }}>
            <Grid item xs={12} sm={12} md={6} sx={gridItemStyle}>
              <Box>
                <Typography className="c_textblack f_alice f_xxxxlarge fw_600 lh_xxxlarge ls_4">
                  Automate your art workflow with Polyhive
                </Typography>

                <Typography className="c_textblack f_raleway f_xlarge fw_400 lh_subtitle ls_4 mt_3">
                  We're happy to answer your questions
                </Typography>
                <List>
                  <ListItem className="mt_3">
                    <ListItemIcon>
                      <CheckRoundedIcon />
                    </ListItemIcon>
                    <ListItemText
                      className="f_raleway"
                      primary="Secure an appointment to talk to our team about your needs."
                    />
                  </ListItem>
                  <ListItem disablePadding className="mt_2">
                    <ListItemIcon>
                      <CheckRoundedIcon />
                    </ListItemIcon>
                    <ListItemText
                      className="f_raleway"
                      primary="Explore use cases based on your industry and business needs."
                    />
                  </ListItem>
                  <ListItem disablePadding className="mt_2">
                    <ListItemIcon>
                      <CheckRoundedIcon />
                    </ListItemIcon>
                    <ListItemText
                      className="f_raleway"
                      primary="Get a custom demo of Polyhive tailored to your business."
                    />
                  </ListItem>
                </List>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} sx={gridItemStyle}>
              <Box className="bg_offwhite br_15" p={5}>
                {formSubmitted === false ? formContent : formSubmittedContent}
              </Box>

              <br />
            </Grid>
          </Grid>
        </VuiBox>
      </VuiBox>
      {/* <Footer /> */}
    </PageLayout>
  );
};

export default Contact;
