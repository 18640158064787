// import React, { useState } from "react";

// ASSETS
// import art_img from "assets/images/art_img.png";
// import gamepadIcon from "assets/icons/gamepadIcon.png";
// import manualIcon from "assets/icons/manualIcon.png";
// import teamIcon from "assets/icons/teamIcon.png";
// import VuiTypography from "components/VuiTypography/index";
// import colors from "assets/theme/base/colors";
import Button from "@mui/material/Button";
// import linearGradient from "assets/theme/functions/linearGradient";
// import VuiBox from "components/VuiBox/index";
// import VideogameAssetRoundedIcon from "@mui/icons-material/VideogameAssetRounded";
// import SettingsIcon from "@mui/icons-material/Settings";
// import GroupsIcon from "@mui/icons-material/Groups";
import { Suspense, useEffect } from "react";

import { Box, Typography, Grid } from "@mui/material";

// import { Canvas } from "@react-three/fiber";
// import { Environment, OrbitControls } from "@react-three/drei";
// import { useLoader } from "@react-three/fiber";
// import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
// import * as THREE from "three";
// import CubeMap2 from "assets/images/envmap2.jpg";
// import DamagedHelmet from "./models/DamagedHelmet.glb";
import FaithRinggoldShirt from "./models/faith_ringgold_shirt.glb";
import GaryPanterShirt from "./models/gary_panter_shirt.glb";
import TieDyeShirt from "./models/tye_dye.glb";
import HieroglyphicsShirt from "./models/hieroglyphics.glb";
// import HomeImage from "assets/images/homegraphic-hexagon.png";
import { Link, useNavigate } from "react-router-dom";
// import Typing from "react-typing-animation";
// import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
// import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
// import ModelGallery from "./components/ModelGallery/index";

// import NvidiaIcon from "assets/icons/nvidia_inception.png";
// import UnityIcon from "assets/icons/unity_solution.png";
// // import FeatureImage from "assets/images/orc_render.png";
// import OctagonImage from "./components/OctagonImage/index";
// import HexagonImage from "./components/HexagonImage/index";
// import HexagonContainer from "./components/HexagonContainer/index";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CarRender from "assets/home/car_render.png";
import PlaneRender from "assets/home/plane_render.png";
import RobotRender from "assets/home/robot_render.png";
import {
  motion,
  useMotionValue,
  useTransform,
  useAnimation,
  MotionStyle,
  MotionValue,
} from "framer-motion";
import { useInView } from "react-intersection-observer";
import { filter } from "lodash";

const heroAssets = [
  {
    file: FaithRinggoldShirt,
    prompt: "A shirt by Faith Ringgold",
  },
  {
    file: GaryPanterShirt,
    prompt: "A shirt by Gary Panter",
  },
  {
    file: TieDyeShirt,
    prompt: "A tie-dye shirt",
  },
  {
    file: HieroglyphicsShirt,
    prompt: "A shirt with hieroglyphics",
  },
];

// generate a random number to pick a random hero asset
const randomIndex = Math.floor(Math.random() * heroAssets.length);

const Hero = () => {
  const text = "Intelligent 3D Design";
  const ctrls = useAnimation();
  const navigate = useNavigate();
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      ctrls.start("visible");
    }
  }, [ctrls, inView]);

  const wordAnimation = {
    hidden: {},
    visible: {},
  };

  const characterAnimation = {
    hidden: {
      opacity: 0,
      y: `0.25em`,
    },
    visible: {
      opacity: 1,
      y: `0em`,
      transition: {
        duration: 1,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
  };
  // useEffect(() => {
  //   handleResize();
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  return (
    <div className="w100">
      <div className="w100 center_column">
        <div className="hero_section">
          <div className="container d_flex d_flex_column d_justify_center d_align_center">
            {/* <div className="container d_flex d_justify_center d_align_center">
        <ModelGallery />
      </div> */}

            <h1 className="f_alice f_title fw_400 c_black lh_1 text_center" aria-label={text}>
              {text.split(" ").map((word, index) => (
                <motion.span
                  ref={ref}
                  aria-hidden="true"
                  key={index}
                  initial="hidden"
                  animate={ctrls}
                  variants={wordAnimation}
                  transition={{
                    delayChildren: index * 0.1,
                    staggerChildren: 0.05,
                  }}
                  className="inline-block whitespace-nowrap mr_2"
                >
                  {word.split("").map((character, index) => (
                    <motion.span
                      aria-hidden="true"
                      key={index}
                      variants={characterAnimation}
                      className="inline-block"
                    >
                      {character}
                    </motion.span>
                  ))}
                </motion.span>
              ))}
            </h1>

            <Typography className="f_raleway f_subtitle fw_400 c_black text_center lh_subtitle mt_2">
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.9, duration: 0.5 }}
              >
                We're a spatial design platform that enables visual artists, engineers, designers
                and more to create production-ready 3D assets in minutes instead of days.
              </motion.p>
            </Typography>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1.1, duration: 0.75 }}
            >
              <Button
                className="c_black br_pentenaryfocus br_15px hero_btn f_small fw_500 mt_3"
                endIcon={<CalendarMonthOutlinedIcon />}
                onClick={() => {
                  navigate("/contact");
                }}
              >
                Contact Sales
              </Button>
            </motion.p>
            <Grid container spacing={2} className="mt_3">
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <motion.img
                  initial={{ opacity: 1, filter: "blur(5px)" }}
                  animate={{ opacity: 1, filter: "blur(0px)" }}
                  transition={{ delay: 1.4, duration: 0.75 }}
                  src={CarRender}
                  alt="Nvidia Inception"
                  className="w100"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <motion.img
                  initial={{ opacity: 1, filter: "blur(5px)" }}
                  animate={{ opacity: 1, filter: "blur(0px)" }}
                  transition={{ delay: 1.7, duration: 0.75 }}
                  src={PlaneRender}
                  alt="Unity Solution"
                  className="w100"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <motion.img
                  initial={{ opacity: 1, filter: "blur(5px)" }}
                  animate={{ opacity: 1, filter: "blur(0px)" }}
                  transition={{ delay: 2.0, duration: 0.75 }}
                  src={RobotRender}
                  alt="Unity Solution"
                  className="w100"
                />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
