/*

Polyhive Dashboard

*/

import { useState, useEffect } from "react";

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";
import DefaultNavbarMobile from "examples/Navbars/DefaultNavbar/DefaultNavbarMobile";
import DefaultNavbarHref from "./DefaultNavbarHref";

// Vision UI Dashboard PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import PolyhiveLogo from "assets/icons/polyhivelogo-transparent-black.png";

// DefaultNavbar dropdown menus
import PagesMenu from "examples/Navbars/DefaultNavbar/Menus/PagesMenu";
// import AuthenticationMenu from "examples/Navbars/DefaultNavbar/Menus/AuthenticationMenu";
import EcommerceMenu from "examples/Navbars/DefaultNavbar/Menus/EcommerceMenu";
import ApplicationsMenu from "examples/Navbars/DefaultNavbar/Menus/ApplicationsMenu";
import { Button, Box } from "@mui/material";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

import { useNavigate } from "react-router-dom";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";

function DefaultNavbar({ routes, transparent, light, action }) {
  const { borderCol } = colors;
  const { borderWidth } = borders;
  const [pagesMenu, setPagesMenu] = useState(false);
  // const [authenticationMenu, setAuthenticationMenu] = useState(false);
  const [ecommerceMenu, setEcommerceMenu] = useState(false);
  const [applicationsMenu, setApplicationsMenu] = useState(false);
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const openPagesMenu = ({ currentTarget }) => setPagesMenu(currentTarget);
  const closePagesMenu = () => setPagesMenu(false);
  // const openAuthenticationMenu = ({ currentTarget }) => setAuthenticationMenu(currentTarget);
  // const closeAuthenticationMenu = () => setAuthenticationMenu(false);
  const openEcommerceMenu = ({ currentTarget }) => setEcommerceMenu(currentTarget);
  const closeEcommerceMenu = () => setEcommerceMenu(false);
  const openApplicationsMenu = ({ currentTarget }) => setApplicationsMenu(currentTarget);
  const closeApplicationsMenu = () => setApplicationsMenu(false);
  const openMobileNavbar = ({ currentTarget }) => setMobileNavbar(currentTarget.parentNode);
  const closeMobileNavbar = () => setMobileNavbar(false);
  const navigate = useNavigate();

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  return (
    <VuiBox
      border={transparent ? "unset" : `${borderWidth[1]} solid ${borderCol.navbar}`}
      width={"100%"}
      shadow={transparent ? "none" : "md"}
      color={light ? "white" : "dark"}
      display="flex"
      alignItems="center"
      position="absolute"
      zIndex={3}
    >
      <VuiBox
        px={{ xs:2, sm: 2, md: 2, lg: 2 }}
        py={{ xs:4, sm: 4, md: 4, lg: 4 }}
        position="absolute"
        // left="50%"
        top="0"
        width="100%"
        height="100%"
        justifyContent="space-between"
        sx={{
          display: "flex",
        }}
      >
        <Box justifyContent="flex-start" alignItems="center" display="flex">
          <VuiBox
            component={Link}
            to="/"
            display="flex"
            alignItems="center"
            sx={{
              textDecoration: "none",
              color: "inherit",
              cursor: "pointer",
            }}
          >
            <img
              src={PolyhiveLogo}
              alt="Polyhive Logo"
              style={{
                width: "40px",
                // marginRight: "10px",
                marginTop: "2px",
              }}
            />
          </VuiBox>
        </Box>

        <Box className="" display="flex" justifyContent="flex-end" alignItems="center">
          <Button
            className="c_black br_pentenaryfocus br_15px hero_btn f_small fw_500"
            endIcon={<CalendarMonthOutlinedIcon />}
            onClick={() => {
              navigate("/contact");
            }}
          >
            Contact Sales
          </Button>
          {/* <Button
              className="c_quaternaryfocusdark br_1px br_quaternaryfocus bg_white br_white hero_btn f_small fw_500 mr_2"
              endIcon={<ArrowForwardRoundedIcon />}
              onClick={() => {
                navigate("/generate");
              }}
            >
              Sign In
            </Button> */}
        </Box>
      </VuiBox>
    </VuiBox>
  );
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
  action: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default DefaultNavbar;
